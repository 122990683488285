/**
 * Configuraciones comunes, no suele ser necesario reemplazarlas
 * Si es necesario modificar alguna de estas configuraciones, hacerlo en environment.custom.ts
 */

export const common = {
  // otros enlaces
  brandNameLink: 'https://vivofacil.com',
  serviceChat: 'https://asistencia.alares.es/',
  videoCallsUrl: 'https://videollamada.vivofacil.com/?kid=AljJRe39SLEsWhMFg2pqTrA',
  complaintsChannel: 'https://vivofacil.com/es/content/24-canal-de-denuncias',

  /**
   * Debe existir la cadena de traducción de cada item
   */
  protectedPeople: {
    empleado: 'protectedPeople.employee',
    pareja: 'protectedPeople.partner',
    hijos: 'protectedPeople.offspring',
    padres: 'protectedPeople.parents',
    suegros: 'protectedPeople.inlaws'
  },

  /**
   * Contraseña por defecto utilizada cuando no hay registro y el usuario no tiene que meter contraseña
   */
  defaultPassword: 'nopassword',

  /**
    * Recaptcha en formularios
    * Si los formularios van protegidos con recaptcha sobreescribir esta variable
    * dándole el valor de la clave pública
    * Valores: false|<clave_publica>
  */
  recaptcha: false,

  /** Se agrupan los servicios por categorías */
  groupByCategories: true,

  /** Enlace adicional ubicado en top menu */
  externalTopLink: {
    /** Enlace habilitado o deshabilitado */
    status: false,
    /** Titulo del enlace */
    title: 'Link externo',
    /** URL del enlace */
    url: 'https://vivofacil.com',
    /** Se añade la cadena `/xxxxx` a la url, donde xxxxx es el identificador del usuario en base64 */
    concatIdentifier: true
  },

  /** Se permiten contraseñas no seguras */
  allowInsecurePasswords: true
};
